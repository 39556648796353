import { toast } from "react-toastify";
import { fetchEditOperatorSuccess, fetchEditOperatorFailure } from "./reducer";
import { setEditSuccessMessage } from "../../toastMessage/action";
import { OPERATOR_CREATE_AND_EDIT_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { documentUpdateFile } from "../../customer/documentupdatefile/thunk";
import { documentUpdateJson, uploadDocApiCall } from "../../../Components/OperatorManagement/Constant/OperatorListingJson";
import { useState } from "react";
import { fetchOperatorbyId } from "../GetOperatorbyID/thunk";
import { createConfigArray } from "../../../Components/OperatorManagement/Constant/ConfigFunction";
import { updateConfigOperator } from "../UpdateConfiguration/thunk";

function excludeUnchangedConfigs(newConfigs, existingConfigs) {
    return newConfigs.filter((newConfig) => {
        const existingConfig = existingConfigs.configurations.find(
            (config) => config.name === newConfig.name
        );
        if (!existingConfig) {
            return true; 
        }
        if (newConfig.value.length !== existingConfig.value.length) {
            return true;
        }
        return !newConfig.value.every((newValueItem, index) => {
            const existingValueItem = existingConfig.value[index];
            return (
                newValueItem.key === existingValueItem.key &&
                newValueItem.value.join() === existingValueItem.value.join()
            );
        });
    });
}


export const editOperator = (jsonObj, operatorId, setLoading, navigate, profile, update, setUpdate, fileSelected, logoFile, editData, setFileSelected, setEditData, finalValues, configData) => async (dispatch) => {
    setLoading(true)
    const initialValues = {};
    const operatorOptions = [];
    const OperatorStatusOptions = [];
    const roleName = localStorage.getItem('roleName')
    await determineBaseUrlAndPerformApiCall(`${OPERATOR_CREATE_AND_EDIT_API}/${operatorId}`, 'PATCH', jsonObj).then((response) => {
        if (response.error) {
            dispatch(fetchEditOperatorFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
        if (!response.error) {
            setLoading(false)
            dispatch(fetchEditOperatorSuccess(response))
            const tasks = [];
            let jsonObj
            if (finalValues) {
                const configArray = createConfigArray(finalValues, response);
                const filteredConfigs = excludeUnchangedConfigs(configArray, configData);
                jsonObj = {
                    "configurations": filteredConfigs,
                    "operatorId": response?.data?.adminDetails?.operatorId
                }
            }
            function deepEqualConfig(config, json) {
                return config.configurations.every(configItem => {
                    const matchFound = json.configurations.some(jsonItem => {
                        if (configItem.name !== jsonItem.name) {
                            return false;
                        }

                        if (configItem.value.length !== jsonItem.value.length) return false;

                        return configItem.value.every((configValueItem, valueIndex) => {
                            const jsonValueItem = jsonItem.value[valueIndex];
                            return configValueItem.key === jsonValueItem.key &&
                                configValueItem.value.join() === jsonValueItem.value.join();
                        });
                    });
                    return matchFound;
                });
            }
            if (!deepEqualConfig(configData, jsonObj)) {
                tasks.push(dispatch(updateConfigOperator(jsonObj, setLoading, navigate)))
            }
            if (logoFile) {
                if (profile && update) {
                    setUpdate(false)
                }
                if (fileSelected && logoFile !== null) {
                    if (profile && update) {
                        const jsonObj = documentUpdateJson(logoFile, operatorId, editData)
                        let partnerOrCustomer = "Operator";
                        tasks.push(dispatch(documentUpdateFile(setLoading, jsonObj, operatorId, editData?.tradingName, partnerOrCustomer, setUpdate, setFileSelected, navigate)))
                    }
                    else {
                        tasks.push(uploadDocApiCall(logoFile, operatorId, editData, setLoading, setFileSelected, dispatch, navigate))
                    }
                }
                else {
                    navigate('/operator-listing');
                    dispatch(setEditSuccessMessage(true))
                }
            }
            if (tasks.length > 0) {
                Promise.all(tasks).then(() => {
                    dispatch(setEditSuccessMessage(true));
                });
            } else {
                navigate('/operator-listing');
                dispatch(setEditSuccessMessage(true));
            }

            if (roleName === "systemUser") {
                // navigate('/operator-listing');
                dispatch(setEditSuccessMessage(true))
            }
            else if (roleName !== "systemUser") {
                dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions, setEditData));
                navigate('/dashboard');
                dispatch(setEditSuccessMessage(true))
            }
        }
    }).catch((errorResp) => { })
};