export const countryOptionss = (countryData) => {
    const uniqueCountries = [...new Set(countryData || [])]; 
    const eventOptions = uniqueCountries.map((item) => ({
        label: item,
        value: item,
    }));

    return eventOptions;
};


export const OperatorOptionsFields = [
    { value: 'telcoAdmin', label: 'Telco' },
    { value: "operatorAdmin", label: 'EV' },
    { value: "genericAdmin", label: 'Generic' },
    { value: "ISPOperatorAdmin", label: 'ISP' }
]

export const OperatorStatusOptions = [
    { value: 'validated', label: 'Active' },
    { value: 'initialized', label: 'Inactive' },
    { value: 'closed', label: 'Cancel' }
]


export const configDisplayNameMapping = {
    ExpiryInSeconds:"Reservation Units Expiration",
    UsageEventsPersistanceMode:"Usage Persistance Mode",
    BillingManagement: "Billing Management",
    SessionAudit:"Session Audit",
    SessionAuditBuffer: "Session Audit Buffer",
    Limit:"Limit",
    paymentManagement: "Payment",
    TaskEnabled: "Tasks",
    BRMTaskEnabled:"BRM Task Integration",
    whatsAppManagement: "WhatsApp",
    notificationManagement: "Notifications",
    identityManagement: "IDM",
    policySpecifications: "Policy Specifications",
    customerRetrieval: "Customer Retrieval",
    selfcare: "Selfcare",
    orderManagement: "Order Management",
    LLM: "LLM",
    taxEnabled: "Tax Management"
};

export const notificationDisplayNameMapping = {
    NotificationEnable: "Enable Notifications",
    SetPassword: "Set Password",
    ProfileUpdate : "Profile Update",
    EmailUpdate : "E-mail Update",
    BalanceNotification: "Balance Notification",
    BalanceAVNotification: "Balance AV Notification",
    CreateCustomer: "Create Customer",
    CustomerUpdate: "Customer Update",
    AddOnPurchase: "Add-on Update",
    PurchaseOffer: "Purchase Offer",
    UnsubscribeEmail: "Unsubscribe Email",
    ChangePlan: "Change Plan",
    PaymentOrder: "Payment Order",
    PaymentConfirmation: "Payment Confirmation",
    CancelProductOrder: "Cancel Product Order",
    SubscriptionFailed: "Subscription Failed",
    SubscriptionConfirmed: "Subscription Confirmed",
    CommissionStatement: "Commission Statement",
    CreditLimitNotification: "Credit Limit Notification",
    PasswordReset: "Password Reset"
}