import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, CardBody, CardTitle, FormGroup, Label, Input } from 'reactstrap';
import { Loader } from '../Utils/Loader';
import { toast, ToastContainer } from 'react-toastify';
import LineChart from './Constant/LightweightCustomerDashboard';
import CustomerGrantBucket from './Constant/customerGrantBucket';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer } from '../../slices/customer/fetchcustomer/thunk';
import { getAttributeByType } from '../../slices/attribute/getattributebytype/thunk';
import { useNavigate } from 'react-router-dom';
import { updateCustomer } from '../../slices/customer/update/thunk';
import { setDocumentSuccessMessage, setEditSuccessMessage, setSuccessMessage } from '../../slices/toastMessage/action';
import CustomerProfileCard from './CustomerProfileCard';
import CustomerSubscriptionCard from './CustomerSubscriptionCard';
import { setWelcomeToast } from '../../slices/welcomeToaster/action';
import { createSelector } from 'reselect';
import CustomerProfile from '../Customers/CustomerProfile';
import { formatDate } from '../Customers/constants/DateFormat';
import { fetchCustomerSummary } from '../../slices/customer/simulate/thunk';
import AggregateResourceCarousel from '../Customers/AggregateResource';

export default function LightWeightDashboard() {
    const [loading, setLoading] = useState(false);
    const [attributeArray, setAttributeArray] = useState([]);
    let noCurrencyArray = []
    let currencyArray = []
    const fetchCustomerSummaryData = useSelector(
        (state) => state.Simulate.simulate
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customerId = localStorage.getItem('customerId')

    useEffect(() => {
        dispatch(getAttributeByType("customer", setLoading))
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchCustomer(setLoading, customerId))
    }, [dispatch])
    const permissions = useSelector((state) => state.permissions.permissions);
    const customerData = useSelector((state) => state.FetchCustomer.fetchCustomer)
    const Attributes = useSelector((state) => state.GetAttributeByType.getAttribute)
    const filteredItems = Attributes?.filter(item => item.selfcareVisible);
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const resourceData = useSelector((state) => state.Resources.resources);
    const [switchStates, setSwitchStates] = useState({});
    const nonauthData = createSelector(
        (state) => state.Layout.layoutModeType,
        (layoutModeType) => layoutModeType
    );
    const layoutModeType = useSelector(nonauthData);
    useEffect(() => {
        const simulateOrPreview = "View";
        dispatch(fetchCustomerSummary(customerId, setLoading, simulateOrPreview));
    }, [dispatch, customerId]);

    useEffect(() => {
        if (customerData && Attributes?.length > 0) {
            const filteredItems = Attributes?.filter(item => item.selfcareVisible);
            const updatedSwitchStates = filteredItems.reduce((acc, item) => {
                const customerAttribute = customerData?.customerInfo?.attributeList?.find(attr => attr.name === item.name);
                if (customerAttribute) {
                    const index = item.values.indexOf(customerAttribute.value);
                    acc[item.name] = index === 1;
                } else {
                    acc[item.name] = false;
                }
                return acc;
            }, {});
            setSwitchStates(updatedSwitchStates);
        }
    }, [customerData, Attributes]);

    const handleSwitchChange = (name, values) => {
        setSwitchStates((prevState) => {
            const newState = !prevState[name];
            const value = newState ? values[1] : values[0];

            setAttributeArray((prevArray) => {
                const existingAttributeIndex = prevArray.findIndex(attr => attr.name === name);

                let updatedArray;
                if (existingAttributeIndex >= 0) {
                    updatedArray = [...prevArray];
                    updatedArray[existingAttributeIndex].value = value;
                } else {
                    updatedArray = [...prevArray, { name, value }];
                }

                const combinedAttributes = [
                    ...(customerData?.customerInfo?.attributeList || []),
                    ...updatedArray
                ];

                const deduplicatedAttributes = deduplicateAttributes(combinedAttributes);

                const removeIsUnique = (attributeList) => {
                    return attributeList.map(attribute => {
                        const { isUnique, ...rest } = attribute;
                        return rest;
                    });
                };

                const jsonObj = {
                    customerId: customerData?.customerInfo?.customerId,
                    firstName: customerData?.customerInfo?.firstName,
                    lastName: customerData?.customerInfo?.lastName,
                    userName: customerData?.customerInfo?.userName,
                    email: customerData?.customerInfo?.email,
                    mobile: customerData?.customerInfo?.mobile,
                    addressLine1: customerData?.customerInfo?.addressLine1,
                    addressLine2: customerData?.customerInfo?.addressLine2,
                    country: customerData?.customerInfo?.country,
                    state: customerData?.customerInfo?.state,
                    city: customerData?.customerInfo?.city,
                    zipCode: customerData?.customerInfo?.zipCode,
                    dialCode: customerData?.customerInfo?.dialCode,
                    billingProfile: { "bdom": customerData?.customerInfo?.billingProfile?.bdom },
                    ...(deduplicatedAttributes?.length !== 0 && {
                        attributeList: removeIsUnique(deduplicatedAttributes),
                    }),
                    ...(customerData?.subscriptionList?.length !== 0 && {
                        subscriptions: customerData?.subscriptionList,
                    }),
                };

                dispatch(updateCustomer(jsonObj, customerId, setLoading, navigate));

                return updatedArray;
            });

            return {
                ...prevState,
                [name]: newState
            };
        });
    };

    const deduplicateAttributes = (attributeList) => {
        const map = new Map();
        attributeList.forEach(attribute => {
            map.set(attribute.name, attribute);
        });
        return Array.from(map.values());
    };

    const showEditSuccessMessage = useSelector(
        (state) => state.SuccessToast.showEditSuccessMessage
    );
    const showWelcomeToast = useSelector(
        (state) => state.welcomeToast.showWelcomeToast
    );
    const showSuccessMessage = useSelector(
        (state) => state.SuccessToast.showSuccessMessage
    );
    const showDocumentSuccessMessage = useSelector(
        (state) => state.SuccessToast.showDocumentSuccessMessage
    );

    useEffect(() => {
        if (showWelcomeToast) {
            toast.success(`Welcome ${localStorage.getItem("userName")}`);
            dispatch(setWelcomeToast(false));
        }
        
        if (showEditSuccessMessage) {
            toast.success(`Attribute Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
        if(showSuccessMessage){
            toast.success(`Plan changed successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showDocumentSuccessMessage) {
            toast.success(`Add On purchased successfully`);
            dispatch(setDocumentSuccessMessage(false));
        }
    }, [showEditSuccessMessage,showSuccessMessage, dispatch]);
    const aggregateResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAggregate);
    return (
        <div className="page-content">
            <Container fluid>
                {loading && <Loader />}
                <ToastContainer position="top-center" />
                <div>
                    <h5 className="text-uppercase fw-bold">My Account</h5>
                    <Row className="d-flex">
                    <Col md={aggregateResources && aggregateResources.length > 0 ? 6 : 4} lg={aggregateResources && aggregateResources.length > 0 ? 3 : 4} className="d-flex flex-column">
                    <CustomerProfileCard customerData={customerData} dispatch={dispatch} customerId={customerId} setLoading={setLoading} />
                        </Col>
                        <Col md={aggregateResources && aggregateResources.length > 0 ? 6 : 4} lg={aggregateResources && aggregateResources.length > 0 ? 3 : 4} className="d-flex flex-column">
                        <CustomerSubscriptionCard fetchCustomerSummaryData={fetchCustomerSummaryData} />
                        </Col>
                        {aggregateResources && aggregateResources.length > 0 && (
                        <Col md="6" lg="3" className="d-flex flex-column">
                            <Card className="border border-2">
                                <header className="mb-4 p-3">
                                    <h2 className="fs-5 fw-medium">Aggregate View</h2>
                                </header>
                                <div className=' mt-2 mb-3'>
                                <AggregateResourceCarousel resources={aggregateResources} />
                                </div>
                            </Card>                        
                        </Col> )}
                        <Col md={aggregateResources && aggregateResources.length > 0 ? 6 : 6} lg={aggregateResources && aggregateResources.length > 0 ? 3 : 4}  className="d-flex flex-column">
                            <Card className="shadow-sm flex-fill">
                                <CardBody className="d-flex flex-column h-100">
                                    <CardTitle className="mb-4" tag="h5">Attributes</CardTitle>
                                    <div className=" border border-2 p-4 rounded-2 d-flex flex-column h-100" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                        {filteredItems.some(item => item?.selfcareVisible && item?.values?.length === 2) ? (
                                            filteredItems.map((item, index) => (
                                                item.selfcareVisible && item.values?.length === 2 && (
                                                    <FormGroup
                                                        key={index}
                                                        className={`d-flex align-items-center mb-4`} >
                                                        <Label for={item.name} className=" mr-3 w-100 ">
                                                            <h5 className='fw-medium'>{item.name}</h5>
                                                        </Label>
                                                        <div className="form-check form-switch mb-3">
                                                            <Input
                                                                type="switch"
                                                                id={`switch-${item.name}`}
                                                                checked={switchStates[item.name] || false}
                                                                onChange={() => handleSwitchChange(item.name, item.values)}
                                                                className="form-check-input"
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                )
                                            ))
                                        ) : (
                                            <div className="d-flex justify-content-center align-items-center h-100">
                                                <div><h5>No Attributes</h5></div>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className=" mt-4">
                        <Col>
                            <CustomerProfile customerPermission={customerPermission} customerData={customerData} noCurrencyArray={noCurrencyArray} currencyArray={currencyArray} fetchCustomerSummaryData={fetchCustomerSummaryData} formatDate={formatDate} resourceData={resourceData} />
                        </Col>
                        {/* <Col md="12">
                            <CustomerGrantBucket />
                        </Col> */}
                    </Row>
                    <Col className=' mt-4'>
                        <Card>
                            <CardBody>
                                <div className=" d-flex justify-content-between">
                                    <h5 className=" ps-2 fw-semibold">Data</h5>
                                    <p className=" d-flex justify-content-center align-items-end mb-4">Trailing 7 Days <i className="ri-error-warning-fill ms-2 icon-lg"></i></p>
                                </div>
                                <div>
                                    <LineChart />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Container>
        </div>
    );
}