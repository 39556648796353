import { handleAddItem } from "./OrderUtil";

export const createdByFunction = (order) => {
    let createdBy = []
    order.forEach(order => {
        let author = '-';
        if (order?.note && order?.note?.length > 0) {
            author = order?.note[0]?.author || '-';
        }
        if (order?.productOrderItem) {
            order?.productOrderItem?.forEach(productOrderItem => {
                if (productOrderItem?.note && productOrderItem?.note?.length > 0) {
                    author = productOrderItem?.note[0]?.author || '-';
                }
                createdBy.push(author);
            });
        }
    });
    return createdBy
}

export const taxIncludedAmountFunctions = (order) => {
    return order.map(productOrder => {
        const totalPrice = productOrder?.orderTotalPrice?.find(price => price?.priceType === "nonRecurring");
        return totalPrice ? totalPrice?.price?.taxIncludedAmount?.value : null;
    });
}

export const namedArrayFunction = (order) => {
    let namesArray = []
    order?.forEach(order => {
        order?.relatedParty?.forEach(relatedParty => {
            namesArray.push(relatedParty?.partyOrPartyRole?.name);
        });
    });
    return namesArray
}

export const handleFormSubmit = (values, selectedOffer, formData, navigate, subscriptionId, dispatch) => {
    handleAddItem(values, selectedOffer, formData, navigate, subscriptionId, dispatch);
};
