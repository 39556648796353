import { LabelText } from "../Utils/Input"

export const SessionCards = ({ value }) => {
    return (
        <div>
            <div className="d-flex">
                <LabelText displayText={"Resource Name : "} />
                <p>&nbsp;{value.resourceName}</p>
            </div>
            <div class="mx-5">
                <div class="row">
                    {/* <!-- Reserved Balance Card --> */}
                    <div class="col-md-4 mb-2">
                        <div class="border rounded border-2">
                            <div class="card-body">
                                <LabelText displayText={"Reserved Balance "} />
                                <h1 className="card-text fw-bold my-4 text-center session-card-font-size">
                                    {value?.reservedBalance ? (
                                        <>
                                            {value?.reservedBalance} <span className="fs-2">{value.uom}</span>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </h1>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Consumed Balance Card --> */}
                    <div class="col-md-4 mb-2">
                        <div class="border rounded border-2">
                            <div class="card-body">
                                <LabelText displayText={"Consumed Balance "} />
                                <h1 className="card-text fw-bold my-4 text-center session-card-font-size">
                                    {value?.consumedBalance ? (
                                        <>
                                            {value?.consumedBalance} <span className="fs-2">{value.uom}</span>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </h1>
                                {/* <h1 className="card-text fw-bold my-4 text-center session-card-font-size">
                                    {value.consumedBalance ? (
                                        <>
                                            {value.consumedBalance} <span className="fs-3">{value.uom}</span>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </h1> */}
                            </div>
                        </div>
                    </div>

                    {/* <!-- Remaining Balance Card --> */}
                    <div class="col-md-4">
                        <div class="border rounded border-2">
                            <div class="card-body">
                                <LabelText displayText={"Remaining Balance "} />
                                <h1 className="card-text fw-bold my-4 text-center session-card-font-size">
                                    {value?.remainingBalance ? (
                                        <>
                                            {value?.remainingBalance} <span className="fs-2">{value.uom}</span>
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </h1>
                                {/* <h2 class="card-text fw-bold my-4 text-center session-card-font-size">
                                    {value.remainingBalance ? `${value.remainingBalance} ${value.uom}` : '-'}
                                </h2> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}