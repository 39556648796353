import { Box } from "@mui/material";
import moment from "moment";
import { getStatusLabel } from "../../Utils/DecideStatus";
import { getCellStyle } from "../../Utils/DecideColor";

export const subscriptionColumns = [
    {
        accessorKey: 'offerName',
        header: 'Offer Name',
        size: 150,
    },
    {
        accessorKey: 'validFrom',
        header: 'Offer Starts',
        Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY"),
        size: 150,
    },
    {
        accessorKey: 'validTo',
        header: 'Offer Ends',
        Cell: ({ cell }) => moment(cell.getValue()).format("DD MMM YYYY"),
        size: 150,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ row, cell }) => {
            const validTo = row.original.validTo;
            const isExpired = moment(validTo).isValid() && moment(validTo).isBefore(moment(), 'day'); 
    
            let statusValue = cell.getValue();
    
            if (validTo !== "0001-01-01T00:00:00Z" && isExpired) {
                statusValue = "Inactive";
            }
    
            return (
                <Box sx={(theme) => (statusValue ? {
                    ...getCellStyle(statusValue, "Subscription"),
                } : {})}>
                    {getStatusLabel(statusValue)}
                </Box>
            );
        }
    },    
    {
        accessorKey: 'addOnOffer',
        header: 'Type',
        size: 150,
        Cell: ({ cell }) => cell.getValue() ? 'Add-On' : 'Base Offer',
    }
];
