import moment from "moment";
import { updateSession } from "../../../slices/sessions/updateSession/thunk";
import { terminateSession } from "../../../slices/sessions/terminateSession/thunk";

export const updateSessionApiCall = (value,dispatch,setLoading,customerId,setUpdateSession,isView,sessionInfo,terminateSessions,setButtonInitiateSession) => {
    const jsonObj = JSON.stringify({
        "eventType":!terminateSessions? "Updated":"Ended",
        "timestamp": moment(value?.sessionTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "sessionId": `TEST-SESSION-${value.sessionId}`, 
        "subscriptionId":sessionInfo?.subscriptionId,
        "seqno": sessionInfo?.sequenceNumber,
        ...(terminateSessions === false && {
            requestedUnit: parseFloat(value?.requestedUnits),
        }),
        ...((terminateSessions === true || value?.usedUnits!='') && {
            usedUnit: parseFloat(value?.usedUnits),
        }),
        "uom": sessionInfo?.uom ,
        "usageEventType": sessionInfo?.usageEventType,
    })
if(!terminateSessions){
    dispatch(updateSession(jsonObj,setLoading,customerId,sessionInfo?.subscriptionId,setUpdateSession,setButtonInitiateSession))
}else{
    dispatch(terminateSession(jsonObj, setLoading,customerId,sessionInfo?.subscriptionId,setUpdateSession,setButtonInitiateSession))
}
   
}
