import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setDocumentSuccessMessage, setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchSubscribeOrderFailure, fetchSubscribeOrderSucess } from "./reducer";
import { SUBSCRIBE_API } from "../../constants/constants";

export const subscribeOrder = (jsonObj, setLoading, navigate, isAddOn) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${SUBSCRIBE_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchSubscribeOrderSucess(response))
            if (isAddOn) {
                navigate('/dashboard');
            } else {
                navigate('/customer');
            }
            dispatch(setDocumentSuccessMessage(true))
        }
        if (response.error) {
            dispatch(fetchSubscribeOrderFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}