const initialState = {
  orders: [],
  selectedOrder: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ORDER':
      const updatedOrders = action.payload.reduce((accumulator, newOrder) => {
        const existingOrderIndex = state.orders.findIndex(order => order.subscriptionID === newOrder.subscriptionID);
        if (existingOrderIndex !== -1) {
          accumulator[existingOrderIndex] = newOrder;
        } else {
          accumulator.push(newOrder);
        }
        return accumulator;
      }, [...state.orders]); 

      return {
        ...state,
        orders: updatedOrders,
      };

    case 'SELECT_ORDER':
      return {
        ...state,
        selectedOrder: action.payload,
      };

    case 'UPDATE_ORDER_STATUS':
      return {
        ...state,
        selectedOrder: null,
        orders: state.orders.map(order => ({
          ...order,
          status: action.payload.state
        })),
      };

    case 'QUANTITY_CHANGE':
      return {
        ...state,
        orders: state.orders.map(order =>order.subscriptionID === action.payload.subscriptionID ? { ...order, quantity: action.payload.quantity }: order),
      };
      
    case 'REMOVE_ORDER':
      return {
        ...state,
        orders: state.orders.filter(order => order.subscriptionID !== action.payload),
        selectedOrder: null,
      };

    case 'CLEAR_ORDERS':
      return {
        ...state,
        orders: [],
        selectedOrder: null,
      };

    default:
      return state;
  }
};

export default orderReducer;
