import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    previewOrder: [],
    loading: false,
    error: null,
};

const previewOrderSlice = createSlice({
    name: "previewOrder",
    initialState,
    reducers: {
        addOrderToPreview: (state, action) => {
            if (Array.isArray(action.payload)) {
                action.payload.forEach((order) => {
                    const exists = state.previewOrder.some(
                        (existingOrder) => existingOrder.subscriptionID === order.subscriptionID
                    );
                    if (!exists) {
                        state.previewOrder.push(order);
                    }
                });
            }else{
            const exists = state.previewOrder.find((order) => (order.subscriptionID === action.payload.subscriptionID))
            if(!exists) 
                state.previewOrder.push(action.payload);
            }
        },
        addQuantity: (state, action) => {
            state.loading=false;
            const orderIndex = state?.previewOrder?.findIndex(order => order?.subscriptionID === action?.payload?.subscriptionID);
            if (orderIndex !== -1) {
                state.previewOrder[orderIndex].quantity = action.payload.quantity;
            }
        },
        deleteOrder: (state, action) => {
            state.loading=false;
            const orderIndex = state?.previewOrder?.findIndex(order => order?.subscriptionID === action?.payload?.subscriptionID);
            if (orderIndex !== -1) {
                state.previewOrder.splice(orderIndex, 1); 
            }
        },
        resetPreview: (state) => {
            state.loading = false;
            state.previewOrder = [];
            state.error = null;
        }
        }
    },
);

export const { addOrderToPreview , addQuantity, deleteOrder, resetPreview} = previewOrderSlice.actions;

export default previewOrderSlice.reducer;