import moment from 'moment';
export const displayInfiniteForOptions = (values, val, resourceData) => {

    resourceData?.forEach((resource) => {
        if (values?.value === resource?.name) {
            if (resource.override) {
                val.infinite = resource?.override;
            }
            else {
                val.infinite = false
            }
        }
    }
    )
}

export const displayCeilingAndFloorForOptions = (values, val, resourceData) => {
    resourceData.forEach((resource, idx) => {
        if (values.value === resourceData[idx].name) {
            if (!("override" in resourceData[idx])) {
                val.ceiling = `${resourceData[idx].ceiling}`
                val.floor = `${resourceData[idx].floor}`
            } else {
                val.ceiling = false
                val.floor = false
            }
        }
    })
}

export const setValidityValidation = (resourceData,val,setDisableValidityInGrants,index) => {
    resourceData?.forEach((resource) => {
        if (val?.value === resource?.name) {
            if (resource?.isCurrency === true) {
                setDisableValidityInGrants((id) => {
                    const newValue = [...id];
                    newValue[index] = true;
                    return newValue;
                })
            }
            else {
                setDisableValidityInGrants((id) => {
                    const newValue = [...id];
                    newValue[index] = false;
                    return newValue;
                })
            }
        }
    })
}

export const handleSelectedService = (value, serviceData, setServiceCode, setSelectedService) => {
    serviceData.forEach((val, index) => {
        if (value?.serviceName?.value === serviceData[index].serviceCode) {
            setServiceCode(value?.serviceName?.value)
            if ("provisioningAttributes" in serviceData[index]) {
                setSelectedService([serviceData[index].provisioningAttributes])
            }
        }
    })
}

export const chargesTabChange = (tab, chargesActiveTab, setChargesActiveTab) => {
    if (chargesActiveTab !== tab) setChargesActiveTab(tab);
}

export const usageTabChange = (tab, usageActiveTab, setUsageActiveTab) => {
    if (usageActiveTab !== tab) setUsageActiveTab(tab);
}

export const handleChargesProrateState = (index, values,setFieldValue) => {
    const newValues = { ...values };
    values.recurringCharges[index].prorate = !values.recurringCharges[index].prorate
    setFieldValue('recurringGrants', newValues.recurringCharges);
}

export const handleProrateState = (index, values,setFieldValue) => {
    const newValues = { ...values };
    newValues.recurringGrants[index].prorate = !newValues.recurringGrants[index].prorate;
    setFieldValue('recurringGrants', newValues.recurringGrants);
}

export const toggleAccordion = (open, setOpen, globalState) => {
    const toggle = (id) => {
        if (open === id) { setOpen(); }
        else {
            if (!globalState) { setOpen(id); }
            else { if (id == '3') { setOpen(id) } }
        }
    };
    return toggle
}
export const endDates = (offer, allEndDate) => {
    offer.forEach((value, index) => {
        const endDate = moment.utc(offer[index]?.validity?.to).format("DD MMM, YYYY")
        if (endDate !== '01 Jan 0001') {
            allEndDate[index] = endDate
        } else {
            allEndDate[index] = 'Never Expiring'
        }
    })
    return allEndDate
}
export const startDates = (offer, allStartDate) => {
    offer.forEach((value, index) => {
        const startDate = moment.utc(offer[index]?.validity?.from).format("DD MMM, YYYY")
        allStartDate[index] = startDate;
    })
    return allStartDate
}
export const columnDataFormat = (offer, globalScope, grant, charge, usage) => {
    offer.forEach((value, index) => {
        if (offer[index]?.globalScope === true) {
            globalScope[index] = "Yes"
        }
        else {
            globalScope[index] = "No"
        }
        if (offer[index]?.Charges?.length > 0) {
            charge[index] = "Charge"
        }
        else {
            charge[index] = ""
        }
        if (offer[index]?.usageRatePlan?.length > 0) {
            usage[index] = "Usage"
        }
        else {
            usage[index] = ""
        }
        if (offer[index]?.Grants?.length > 0) {
            grant[index] = "Grant"
        }
        else {
            grant[index] = ""
        }
    })
}

export const setEndsOnOption = (state, SubscriptionEndsOnOptions) => {
    const index = SubscriptionEndsOnOptions.map((val) => val.label).indexOf("Forever")
    if (state && index > -1) {
        SubscriptionEndsOnOptions.splice(index, 1)
    }
    else if (index === -1) {
        SubscriptionEndsOnOptions.push({ value: 4, label: "Forever" })
    }
}

export const setValueForSubValStartsOn = (initialValues, setFieldValue, selectedValue) => {
    setFieldValue('subscriptionStartsOn', selectedValue)
    if (selectedValue.value === 1) {
        setFieldValue("timePeriodStart", 0)
        setFieldValue("timePeriodOptionStart", { value: '0', label: '' })
    }
    if (selectedValue.value === 3) {
        setFieldValue("timePeriodStart", 1)
        setFieldValue("timePeriodOptionStart", { value: '6', label: 'Yearly' })
    }
};

export const setValueForSubValEndsOn = (initialValues, setFieldValue, selectedValue) => {
    setFieldValue('subscriptionEndsOn', selectedValue)
    if (selectedValue.value === 0) {
        setFieldValue("timePeriod", 0)
        setFieldValue("timePeriodOption", { value: '0', label: '' })
    }
    if (selectedValue.value === 3) {
        setFieldValue("timePeriod", 1)
        setFieldValue("timePeriodOption", { value: '6', label: 'Yearly' })
    }
}