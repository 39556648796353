import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Container, Row, Col, Button, Nav, NavItem, NavLink, Carousel, CarouselIndicators, CarouselItem, CarouselControl, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, } from "reactstrap";
import { getAllService } from "../../slices/services/thunk";
import { fetchAllOffers } from "../../slices/offer/thunk";
import { fetchCustomerSummary } from "../../slices/customer/simulate/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import moment from "moment";
import CommonButton from "../Utils/CommonButton";
import { submitChangePlan } from "../Customers/constants/ChangePlanSubmitFunction";
import { useLocation, useNavigate } from "react-router-dom";
import { jsonForAddOn } from "./Constant/JsonForAddOn";
import { calculateValidity } from "./Constant/DateValidity";
const ChangePlan = () => {
    const dispatch = useDispatch();
    const dispatchForChangePlan = useDispatch()
    const customerId = localStorage.getItem('customerId')
    const subscriptionId = customerIdData();
    const location = useLocation();
    const navigate = useNavigate()
    const isSelfcareChangePlan = location.pathname.includes("selfcare-changeplan")
    const isAddOn = !isSelfcareChangePlan
    let simulateOrPreview = ""
    const [activeTab, setActiveTab] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [groupedPlans, setGroupedPlans] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); 
    const [selectedOption, setSelectedOption] = useState("");
    const [modalStep, setModalStep] = useState(1); 
    const [immediate, setImmediate] = useState(false)
    const [immediateMinus, setImmediateMinus] = useState(false)
    const [nextBillCycle, setNextBillCycle] = useState(false)
    const [postExpiry, setPostExpiry] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(null);
    const toggleModal = () => setModalOpen(!modalOpen);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleDescription = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    useEffect(() => {
        if (!isSelfcareChangePlan) {
            setModalStep(2);
        }
    }, [isSelfcareChangePlan]);
    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        setImmediate(false);
        setImmediateMinus(false);
        setNextBillCycle(false);
        setPostExpiry(false);
        switch (value) {
            case "immediate":
                setImmediate(true);
                break;
            case "immediateMinus":
                setImmediateMinus(true);
                break;
            case "next":
                setNextBillCycle(true);
                break;
            case "postExpiry":
                setPostExpiry(true);
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        dispatch(getAllService(setLoading));
        dispatch(fetchAllOffers(setLoading));
        simulateOrPreview = "View";
        dispatch(fetchCustomerSummary(customerId, setLoading, simulateOrPreview));
    }, []);
    const serviceData = useSelector((state) => state.Services.services);
    const offers = useSelector((state) => state.Offer.offer);
    const fetchCustomerSummaryData = useSelector((state) => state.Simulate.simulate);
    const matchingResource = fetchCustomerSummaryData?.resources?.find(
        (resource) => resource.subscriptionId === subscriptionId && !resource.isAddOn
    );
    const matchingResourceArray = fetchCustomerSummaryData?.resources
        ?.filter((resource) => resource.subscriptionId === subscriptionId) || [];
    const matchedOffers = offers
        .filter((offer) => offer.addOnOffer !== isSelfcareChangePlan)

        .map((offer) => {
            const matchedService = serviceData.find(
                (service) => service.serviceCode === offer.serviceCode
            );
            if (matchedService) {
                return {
                    serviceName: matchedService.name,
                    serviceCode: matchedService.serviceCode,
                    offerCode: offer.offerCode,
                    offerName: offer.offerName,
                    startDate: offer.validity?.from || "N/A",
                    endDate: offer.validity?.to || "N/A",
                    amount: offer.Charges?.[0]?.amount || 0,
                    Grants: offer.Grants || [],
                    description: offer?.description
                };
            }
            return null;
        })
        .filter(Boolean);

    const groupedOffers = matchedOffers.reduce((acc, offer) => {
        acc[offer.serviceName] = acc[offer.serviceName] || [];
        const grants = offer.Grants || [];

        let description = offer?.description?.trim() ? offer.description : "";
        if (!description && grants.length > 0) {
            description = grants.map(grant =>
                `${grant.amount} ${grant.measuringUnits || ""} ${grant.resourceName}`
            ).join(", ");
        }
        const isCurrentPlan = matchingResourceArray.some(
            resource => resource.offerCode === offer.offerCode
        ) && isSelfcareChangePlan 
        acc[offer.serviceName].push({
            title: offer.offerName,
            offerCode: offer.offerCode,
            price: `₹${offer.amount || 0}`,
            validity: calculateValidity(offer.startDate, offer.endDate) + " Days",
            description: description || 'No Grants available!',
            startDate: offer.startDate,
            endDate: offer.endDate,
            isCurrentPlan: isCurrentPlan
        });
        return acc;
    }, {});
    useEffect(() => {
        if (Object.keys(groupedOffers).length > 0 && !activeTab) {
            setActiveTab(Object.keys(groupedOffers)[0]);
        }
    }, [groupedOffers]);
    useEffect(() => {
        if (activeTab) {
            const plans = groupedOffers[activeTab] || [];
            const grouped = [];
            if (plans.length <= 4) {
                grouped.push(plans);
            } else {
                for (let i = 0; i < plans.length; i += 4) {
                    const group = plans.slice(i, Math.min(i + 4, plans.length));
                    grouped.push(group);
                }
            }
            if (grouped.length > 0 && grouped[grouped.length - 1].length < 4 && plans.length > 4) {
                const lastGroup = grouped[grouped.length - 1];
                while (lastGroup.length < 4) {
                    lastGroup.push(plans[lastGroup.length % plans.length]); 
                }
            }
            setGroupedPlans(grouped);
            setActiveIndex(0);
        }
    }, [activeTab]);
    const next = () => {
        if (animating) return;
        setActiveIndex(activeIndex === groupedPlans.length - 1 ? 0 : activeIndex + 1);
    };
    const previous = () => {
        if (animating) return;
        setActiveIndex(activeIndex === 0 ? groupedPlans.length - 1 : activeIndex - 1);
    };
    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    const handleConfirm = () => {
        if (modalStep === 1) {
            setModalStep(2);
        } else {
            if (isSelfcareChangePlan) {
                submitChangePlan(customerId, selectedPlan?.offerCode, subscriptionId, immediate, immediateMinus, nextBillCycle, setLoading, dispatchForChangePlan, true, postExpiry, matchingResource?.offerCode, isSelfcareChangePlan, navigate)
            } else {
                jsonForAddOn(subscriptionId, selectedPlan?.offerCode, customerId, dispatch, setLoading, navigate, isAddOn)
            }
            toggleModal();
            setModalStep(1);
        }
    };
    return (
        <div className="page-content">
            <Container fluid>
                <ToastContainer position="top-center" />
                <h5 className="text-uppercase fw-bold">{isSelfcareChangePlan ? 'Change Plan' : 'Add On'}</h5>
                <Card className="mt-2">
                    <Nav className="mb-2 mt-2 ms-2 g-2 d-flex">
                        {Object.keys(groupedOffers).map((service) => (
                            <NavItem key={service} className="me-2">
                                <NavLink
                                    className={`rounded-1 border  border-1 px-3 py-2 text-decoration-none text-black ${activeTab === service ? "active bg-light border-1 fw-bold" : "border-light"}`}
                                    onClick={() => setActiveTab(service)}
                                >
                                    {service}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <CardBody className="px-4 ">
                        {groupedPlans.length > 1 ? (
                            <Carousel activeIndex={activeIndex} next={next} previous={previous} interval={false}>
                                <CarouselIndicators items={groupedPlans} activeIndex={activeIndex} onClickHandler={goToIndex} />
                                {groupedPlans.map((group, index) => (
                                    <CarouselItem key={index} onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)}>
                                        <Row className="g-3">
                                            {group.map((plan, planIndex) => (
                                                <Col key={planIndex} md={6} lg={3} className="selfcare-cardheight">
                                                    <Card className="shadow-sm custom-border-offer border-gray text-center h-100 d-flex flex-column">
                                                        <CardBody className="d-flex flex-column">
                                                            <h6 className="fw-bold offer-description mb-3">{plan.title}</h6>
                                                            <div className="offer-price-tag pt-4 pb-2">
                                                                <h1 className="fw-bold text-gray">{plan.price}</h1>
                                                                <p className="text-black">Validity <span className="fw-semibold">{plan.validity}</span></p>
                                                            </div>
                                                            <div className={`selfcare-description mt-3 fs-5 ${expandedIndex === planIndex ? 'expanded' : ''}`}
                                                                style={{
                                                                    maxHeight: expandedIndex === planIndex ? "400px" : "170px",
                                                                    overflowY: "auto"
                                                                }}>
                                                                {plan.description}
                                                            </div>
                                                            {plan.description.length > 500 && (
                                                                <Button color="link" className="p-0 text-primary shadow-none" onClick={() => toggleDescription(planIndex)}>
                                                                    {expandedIndex === planIndex ? "Show Less" : "Show More"}
                                                                </Button>
                                                            )}
                                                            <div className="mt-auto">
                                                                {plan.isCurrentPlan ? (
                                                                   <Button  outline className="fw-bold task-color border border-primary " disabled>
                                                                   Current Plan
                                                               </Button>
                                                                ) : (
                                                                    <Button color="primary" onClick={() => {
                                                                        setSelectedPlan(plan);
                                                                        setModalOpen(true);
                                                                    }} className="fw-bold">
                                                                        Select
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </CarouselItem>
                                ))}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} className="custom-prev" />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={next} className="custom-next" />
                            </Carousel>
                        ) : (
                            <Row className="g-3">
                                {groupedPlans.flat().map((plan, index) => (
                                    <Col key={index} md={6} lg={3} className="selfcare-cardheight">
                                        <Card className="shadow-sm custom-border-offer border-gray text-center h-100 d-flex flex-column">
                                            <CardBody className="d-flex flex-column ">
                                                <h6 className="fw-bold offer-description mb-3">{plan.title}</h6>
                                                <div className="offer-price-tag pt-4 pb-2">
                                                    <h1 className="fw-bold text-gray">{plan.price}</h1>
                                                    <p className="text-black">Validity <span className="fw-semibold">{plan.validity}</span></p>
                                                </div>
                                                <div className="offer-description mt-2 fs-5" style={{
                                                    maxHeight: expandedIndex === index ? "none" : "170px",
                                                    overflow: expandedIndex === index ? "auto" : "hidden",
                                                    transition: "max-height 0.3s ease-in-out"
                                                }}>
                                                    {plan.description}
                                                </div>
                                                {plan.description.length > 500 && (
                                                    <Button color="link" className="p-0 text-primary shadow-none" onClick={() => toggleDescription(index)}>
                                                        {expandedIndex === index ? "Show Less" : "Show More"}
                                                    </Button>
                                                )}
                                                <div className="mt-auto">
                                                    {plan.isCurrentPlan ? (
                                                        <Button  outline  className="task-color border border-primary " disabled>
                                                            Current Plan
                                                        </Button>
                                                    ) : (
                                                        <Button color="primary" onClick={() => {
                                                            setSelectedPlan(plan);
                                                            setModalOpen(true);
                                                        }} className="fw-bold">
                                                            Select
                                                        </Button>
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </Container>
            <Modal isOpen={modalOpen} toggle={toggleModal} centered={true} size="lg" className="custom-modal">
                <h5 className="text-center w-100 mt-3 fw-semibold">{modalStep === 1 ? "Mode of Change" : "Confirm Plan"}</h5>

                <ModalBody>
                    {(modalStep === 1 && isSelfcareChangePlan) ? (
                        <>
                            <Row className="justify-content-center">
                                <Col sm="6">
                                    <FormGroup className="border border-1 p-1 rounded-1">
                                        <Label check>
                                            <Input type="radio" className="mt-3 ms-1" name="planOption" value="immediate" onChange={handleRadioChange} /> <span className="mt-n1 ms-2">Immediate</span>
                                        </Label>
                                        <p className="changeplan-description tooltip-color-changeplan">Changes immediately</p>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className="border border-1 rounded-1 p-1">
                                        <Label check>
                                            <Input type="radio" className="mt-3 ms-1" name="planOption" value="next" onChange={handleRadioChange} /><span className="mt-n1 ms-2"> Next bill cycle</span>
                                        </Label>
                                        <p className="changeplan-description tooltip-color-changeplan">Changes in next bill cycle</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="mt-2 justify-content-center">
                                <Col sm="6">
                                    <FormGroup className="border border-1 p-4 rounded-1">
                                        <Label check>
                                            <Input type="radio" className="ms-n3" name="planOption" value="immediateMinus" onChange={handleRadioChange} /><span className="ms-2">Immediate minus used</span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup className="border border-1 p-4 rounded-1">
                                        <Label check>
                                            <Input type="radio" className="ms-n3" name="planOption" value="postExpiry" onChange={handleRadioChange} /><span className="ms-2">Post Expiry</span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Col className="mb-3">
                                <FormGroup className="border border-1 rounded-1 p-2">
                                    <Label className="mb-2 fw-bold fs-5 ms-2 pl-2">{!isSelfcareChangePlan ? "Base plan" : " Current Plan"} </Label>
                                    <Row className="mb-2">
                                        <Col lg={3} className="justify-content-center fw-semibold text-start ms-2">Plan Name</Col>
                                        <Col lg={4} className="fw-semibold">Start Date</Col>
                                        <Col lg={4} className="fw-semibold">End Date</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg={3} className="ms-2">{matchingResource?.offerName}</Col>
                                        <Col lg={4}>{moment(matchingResource?.validFrom).format('DD MMM YYYY')}</Col>
                                        <Col lg={4}>{moment(matchingResource?.validTo).format('DD MMM YYYY')}</Col>
                                        <Col lg={4} className="mt-2 ms-2 fw-semibold invoice-icon-size plan-amount">$30</Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col className="mb-3">
                                <FormGroup className="border border-1 rounded-1 new-plan p-2">
                                    <Label className="mb-2 fs-5 fw-bold ms-2">{!isSelfcareChangePlan ? "Add On" : "New Plan"}</Label>
                                    <Row className="mb-2">
                                        <Col lg={3} className="fw-semibold ms-2">Plan Name</Col>
                                        <Col lg={4} className="fw-semibold">Start Date</Col>
                                        <Col lg={4} className="fw-semibold">End Date</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col lg={3} className="ms-2">{selectedPlan?.title}</Col>
                                        <Col lg={4}>{moment().format('DD MMM YYYY')}</Col>
                                        <Col lg={4}>{moment(selectedPlan?.endDate).format('DD MMM YYYY')}</Col>
                                        <Col lg={4} className="mt-2 ms-2 fw-semibold invoice-icon-size plan-amount">{selectedPlan?.price}</Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <CommonButton
                        outline={true}
                        className={"cancel-button-background"}
                        color={"main-color"}
                        type={"reset"}
                        buttonAction={toggleModal}
                        buttonText={"Cancel"}
                    />
                    <CommonButton
                        color={"main-color"}
                        type={"submit"}
                        buttonText={modalStep === 1 ? "Next" : "Confirm"}
                        className={'py-2'}
                        buttonAction={handleConfirm}
                    />
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ChangePlan;
