import * as Yup from "yup"
export const validationForOffer = (state,isEdit,isClone,disableValidityInGrantOne,disableValidityInGrantRecurring) => {
    let validationSchema = {}
    validationSchema.grants = Yup.array().of(
        Yup.object().shape({
            resourceType: Yup.object().required("Kindly select resource name"),
            resourceValue: Yup.number().required("Kindly enter resource Value"),
            validityStartsOn: Yup.mixed().test(
                'validityStartsOn-required',
                'Kindly select validity starts on',
                function(value) {
                    const { path, parent } = this;
                    const index = parseInt(path.match(/\d+/)[0], 10);

                    if (!disableValidityInGrantOne[index]) {
                        return value ? true : this.createError({ message: 'Kindly select validity starts on' });
                    }
                    return true; 
                }
            ),
            validityEndsOn: Yup.mixed().test(
                'validityEndsOn-required',
                'Kindly select validity ends on',
                function(value) {
                    const { path, parent } = this;
                    const index = parseInt(path.match(/\d+/)[0], 10);

                    if (!disableValidityInGrantOne[index]) {
                        return value ? true : this.createError({ message: 'Kindly select validity ends on' });
                    }
                    return true; 
                }
            ),
        })
    );

    validationSchema.recurringGrants = Yup.array().of(
        Yup.object().shape({
            resourceType: Yup.object().required("Kindly select resource name"),
            resourceValue: Yup.number().required("Kindly enter resource Value"),
            cycleAlignment:!isEdit? Yup.object().required("Kindly select cycle alignment"):Yup.object(),
            validityStartsOn: Yup.mixed().test(
                'validityStartsOn-required',
                'Kindly select validity starts on',
                function(value) {
                    const { path, parent } = this;
                    const index = parseInt(path.match(/\d+/)[0], 10);

                    if (!disableValidityInGrantRecurring[index]) {
                        return value ? true : this.createError({ message: 'Kindly select validity starts on' });
                    }
                    return true; 
                }
            ),
            validityEndsOn: Yup.mixed().test(
                'validityEndsOn-required',
                'Kindly select validity ends on',
                function(value) {
                    const { path, parent } = this;
                    const index = parseInt(path.match(/\d+/)[0], 10);

                    if (!disableValidityInGrantRecurring[index]) {
                        return value ? true : this.createError({ message: 'Kindly select validity ends on' });
                    }
                    return true; 
                }
            ),
        })
    )
       
    return validationSchema = Yup.object({
        offerName: Yup.string().required('Kindly enter Offer Name'),
        offerCode: Yup.string().required('Kindly enter Offer Code'),
        subscriptionStartsOn: !state && !isEdit ? Yup.mixed().required('Kindly select a value') : Yup.mixed(),
        subscriptionEndsOn: !state && !isEdit ? Yup.mixed().required('Kindly select a value') : Yup.mixed(),
        offerStartsOn: Yup.mixed().required('Kindly select start date'),
        offerEndsOn: !state && Yup.mixed().required('Kindly select end date'),
        serviceName:!isEdit && Yup.object().required('Kindly select a service name'),
        grants: validationSchema.grants,
        recurringGrants: validationSchema.recurringGrants,
        charges: Yup.array().of(
            Yup.object().shape({
                resourceType: Yup.object().required("Kindly select resource name"),
                resourceValue: Yup.number().required("Kindly enter resource Value")
            })
        ),
        recurringCharges: Yup.array().of(
            Yup.object().shape({
                resourceType: Yup.object().required("Kindly select resource name"),
                resourceValue: Yup.number().required("Kindly enter resource Value")
            })
        ),
        usageRatePlan: Yup.array().of(
            Yup.object().shape({
                usageId: Yup.number().positive('A usage id can\'t start with a minus').integer('It can\'t include a decimal point').required("Kindly enter a valid ID!"),
                eventType: Yup.string().required("Kindly enter a valid event type"),
                resourceName: Yup.mixed().required("Kindly choose a resource"),
                unitOfMeasurement: Yup.mixed().required("Kindly choose an unit of measurement"),
                priority: Yup.number().positive('A priority can\'t start with a minus').integer('It can\'t include a decimal point').required("Kindly enter a valid priority"),
                //perUnitRate: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter a valid per unit rate"), //validation is added down
                flatRate: Yup.number().required("Kindly enter a valid flat rate"),
                pricingModel: !isEdit?Yup.mixed().required("Kindly choose an pricing model"):Yup.mixed(),
                pricingModelNew:  Yup.array().of(
                    Yup.object().shape({
                        startingUnit: Yup.mixed().required("Kindly choose an starting unit for pricing model"),
                        endingUnit: Yup.mixed().required("Kindly choose anending unit for pricing model"), 
                        pricePerUnit: Yup.mixed().required("Kindly choose an price per unit for pricing model")
                    })
                ),
                validityStart:Yup.mixed().required("Kindly choose validity start"),
                validityEnd:Yup.mixed().required("Kindly choose validity end"),
            })
        ),
        recurringUsageRatePlan: Yup.array().of(
            Yup.object().shape({
                usageId: Yup.number().positive('A usage id can\'t start with a minus').integer('It can\'t include a decimal point').required("Kindly enter a valid ID!"),
                eventType: Yup.string().required("Kindly enter a valid event type"),
                resourceName: Yup.mixed().required("Kindly choose a resource"),
                unitOfMeasurement: Yup.mixed().required("Kindly choose an unit of measurement"),
                priority: Yup.number().positive('A priority can\'t start with a minus').integer('It can\'t include a decimal point').required("Kindly enter a valid priority"),
                //perUnitRate: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter a valid per unit rate"), //validation is added down
                flatRate: Yup.number().required("Kindly enter a valid flat rate"),
                pricingModel: !isEdit?Yup.mixed().required("Kindly choose an pricing model"):Yup.mixed(),
                pricingModelNew:  Yup.array().of(
                    Yup.object().shape({
                        startingUnit: Yup.mixed().required("Kindly choose an starting unit for pricing model"),
                        endingUnit: Yup.mixed().required("Kindly choose anending unit for pricing model"), 
                        pricePerUnit: Yup.mixed().required("Kindly choose an price per unit for pricing model")
                    })
                ),
                validityStart:Yup.mixed().required("Kindly choose validity start"),
                validityEnd:Yup.mixed().required("Kindly choose validity end"),
                recurringFrequency:Yup.mixed().required("Kindly choose Recurring Frequency"),
                cycleAlignment:Yup.mixed().required("Kindly choose Cycle Alignment"),
            })
        ),
        effectiveAt: isEdit && Yup.mixed().required('Kindly select Effective At'),
    }, [])
}

export const validationSchemaForImport = Yup.object().shape({
    csvFile: Yup.string().required('Kindly Select a csv file'),
});