import { PREVIEW_ORDER } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"

export const getPreview = (payload, setTaxDetails,setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${PREVIEW_ORDER}`, 'POST', payload).then((response) => {
        if (!response.error) {
            setLoading(false)
            setTaxDetails(response);
        }else{
            setLoading(false)
        }
    }).catch((error) => { 
        setLoading(false);
    })
}