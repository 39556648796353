import moment from "moment";
import { subscribeOrder } from "../../../slices/orderManagement/Subscribe/thunk";

export const jsonForAddOn=(subscriptionId,offerCode,CustomerId,dispatch,setLoading,navigate,isAddOn)=>{
    const jsonData = {
        subscriptionID:subscriptionId ,
        offerCode: offerCode,
        customerId:CustomerId,
        validFrom:  moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        // ...(values.startsOn && {validFrom: moment(values.startsOn?._d).format('YYYY-MM-DDTHH:mm:ss[Z]'),}),
        // ...(endsOn && { validTo: moment(endsOn?._d).format('YYYY-MM-DDTHH:mm:ss[Z]'), })
    };
    dispatch(subscribeOrder(jsonData, setLoading, navigate,isAddOn));

}