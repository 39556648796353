export const createPreviewPayload = (orderData) => {
    const payload = {};
    let totalAmount = 0;
    payload.Charges = orderData.map((order,index) => {
        totalAmount += (order?.unitPrice * order?.quantity)
        return{
            ItemId: index.toString(),
            Quantity: order?.quantity,
            Charge: order?.unitPrice
        }
    })
    payload.customerId = orderData[0]?.CustomerId
    payload.AmountWithoutTax = totalAmount
    return payload;
}